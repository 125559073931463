<template>
  <div :class="['columns', 'columns-layout-' + LAYOUT_CLASSES[layout]]">
    <GridColumn
      v-for="(nestedEl, index) in nestedElements"
      :key="nestedEl.id"
      :element="nestedEl"
      :layout="layout"
      :column-number="index + 1"
    />
  </div>
</template>

<script setup>
  import GridColumn from "./grid_column"

  const LAYOUT_CLASSES = {
    1: "one", // 50/50
    2: "two", // 66/33
    3: "three", // 33/66
    4: "four", // 33/33/33
  }

  const props = defineProps({
    element: {
      type: Object,
      required: true,
    },
  })

  const layout = props.element.ingredients.find(
    (ingredient) => ingredient.role === "layout",
  ).value

  const nestedElements = ref(props.element.nestedElements)
</script>

<style lang="scss">
  .columns {
    max-width: $content-max-width;
    display: grid;
    margin: 0 auto $space-m auto;

    &-layout-one {
      grid-template-columns: 1fr 1fr;
    }

    &-layout-two {
      grid-template-columns: 2fr 1fr;
    }

    &-layout-three {
      grid-template-columns: 1fr 2fr;
    }

    &-layout-four {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include viewport("mini", "sm") {
      grid-template-columns: 1fr;
    }
  }
</style>
